body {
    font-family: "Quicksand", Arial, sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.8;
    color: rgba(0, 0, 0, 0.7); }
    @media screen and (max-width: 992px) {
      body {
        font-size: 16px; } }

a {
    color: #2c98f0;
    text-decoration: none;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s; }
    
a:hover, a:active, a:focus {
    color: #2c98f0;
    outline: none;
    text-decoration: none !important; }

#container-fluid {
    width: 100%;
    overflow: hidden;
    position: relative; }

.container-wrap {
    max-width: 2000px;
    margin: 0 auto; }

.sidebar {
    height: 100%;
    width: 290px;
    float: left;
    position: fixed;
    background: #f2f3f7;
    padding-top: 3em;
    padding-bottom: 40px;
    overflow-y: scroll;
    z-index: 1001;
    border-right: solid 1px lightgrey;
    transition: 0.5s;
}

@media screen and (max-width: 768px) {
    .sidebar {
        width: 290px;
        -moz-transform: translateX(-290px);
        -webkit-transform: translateX(-290px);
        -ms-transform: translateX(-290px);
        -o-transform: translateX(-290px);
        transform: translateX(-290px);
        padding-top: 4em;
    }
}

.sidebar .author-img {
    width: 150px;
    height: 150px;
    margin: 0 auto;
    margin-bottom: 30px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
}

.author-img {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative; }

.sidebar .position {
    display: block;
    text-align: center;
    margin-bottom: 2em;
    font-size: 12px;
    text-transform: uppercase; }

.sidebar #colorlib-logo {
    text-align: right;
    font-weight: 700;
    font-size: 22px;
    margin-bottom: .5em;
    display: block;
    width: 100%; }

.sidebar #colorlib-logo a {
    display: block;
    text-align: center;
    color: #000;
    padding-right: .3em; }

.mainpage {
    width: calc(100% - 290px);
    float: right;
    transition: 0.5s;
}

/* .float{
    position:fixed;
    width:60px;
    height:60px;
    top:5x;
    left:7px;
    background-color:#eff7f5;
    color:#231f20;
    border-radius:50px;
    text-align:center;
    border: solid 1px lightgrey;
}

.float .my-float{
   margin-top:12px;
   width:40px;
   height:40px
}

@media screen and (min-width: 768px) {
    .float{
        display: none;
    }
} */

.sidebar #colorlib-main-menu {
    display: block;
    width: 100%; }
    .sidebar #colorlib-main-menu .collapse {
      display: block !important; }
    .sidebar #colorlib-main-menu ul {
      text-align: center;
      margin: 0;
      padding: 0; }
      @media screen and (max-width: 768px) {
        .sidebar #colorlib-main-menu ul {
          margin: 0 0 2em 0; } }
      .sidebar #colorlib-main-menu ul li {
        margin: 0 0 10px 0;
        padding: 0;
        list-style: none;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px; }
        .sidebar #colorlib-main-menu ul li a {
          color: rgba(0, 0, 0, 0.7);
          text-decoration: none;
          position: relative;
          padding: 10px 0;
          font-family: "Quicksand", Arial, sans-serif;
          -webkit-transition: 0.3s;
          -o-transition: 0.3s;
          transition: 0.3s; }
          .sidebar #colorlib-main-menu ul li a:after {
            content: "";
            position: absolute;
            height: 1px;
            bottom: 7px;
            left: 0;
            right: 0;
            background-color: #2c98f0;
            visibility: hidden;
            -webkit-transform: scaleX(0);
            -moz-transform: scaleX(0);
            -ms-transform: scaleX(0);
            -o-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            -moz-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            -ms-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            -o-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275); }
          .sidebar #colorlib-main-menu ul li a:hover {
            text-decoration: none;
            color: black; }
            .sidebar #colorlib-main-menu ul li a:hover:after {
              visibility: visible;
              -webkit-transform: scaleX(1);
              -moz-transform: scaleX(1);
              -ms-transform: scaleX(1);
              -o-transform: scaleX(1);
              transform: scaleX(1); }
        .sidebar #colorlib-main-menu ul li.active a {
          color: #2c98f0; }
          .sidebar #colorlib-main-menu ul li.active a:after {
            visibility: visible;
            -webkit-transform: scaleX(1);
            -moz-transform: scaleX(1);
            -ms-transform: scaleX(1);
            -o-transform: scaleX(1);
            transform: scaleX(1); }

            body.offcanvas {
                overflow-x: hidden; }
                body.offcanvas .sidebar {
                  -moz-transform: translateX(0);
                  -webkit-transform: translateX(0);
                  -ms-transform: translateX(0);
                  -o-transform: translateX(0);
                  transform: translateX(0);
                  width: 290px;
                  background: #f2f3f7;
                  z-index: 999;
                  position: fixed; }
                body.offcanvas #TheMainPage, body.offcanvas .float {
                  top: 0;
                  -moz-transform: translateX(290px);
                  -webkit-transform: translateX(290px);
                  -ms-transform: translateX(290px);
                  -o-transform: translateX(290px);
                  transform: translateX(290px); }

                  .float {
                    cursor: pointer;
                    text-decoration: none; }
                    .float.active i::before, .float.active i::after {
                      background: #000; }
                    .float.dark.active i::before, .float.dark.active i::after {
                      background: #000; }
                    .float:hover, .float:focus, .float:active {
                      outline: none;
                      border-bottom: none !important; }
                    .float i {
                      position: relative;
                      display: -moz-inline-stack;
                      display: inline-block;
                      zoom: 1;
                      *display: inline;
                      width: 30px;
                      height: 2px;
                      color: #000;
                      font: bold 14px/.4 Helvetica;
                      text-transform: uppercase;
                      text-indent: -55px;
                      background: #000;
                      -webkit-transition: all .2s ease-out;
                      -o-transition: all .2s ease-out;
                      transition: all .2s ease-out; }
                      .float i::before, .float i::after {
                        content: '';
                        width: 30px;
                        height: 2px;
                        background: #000;
                        position: absolute;
                        left: 0;
                        -webkit-transition: 0.2s;
                        -o-transition: 0.2s;
                        transition: 0.2s; }
                    .float.dark i {
                      position: relative;
                      color: #000;
                      background: #000;
                      -webkit-transition: all .2s ease-out;
                      -o-transition: all .2s ease-out;
                      transition: all .2s ease-out; }
                      .float.dark i::before, .float.dark i::after {
                        background: #000;
                        -webkit-transition: 0.2s;
                        -o-transition: 0.2s;
                        transition: 0.2s; }
                  
                  .float i::before {
                    top: -7px; }
                  
                  .float i::after {
                    bottom: -7px; }
                  
                  .float:hover i::before {
                    top: -10px; }
                  
                  .float:hover i::after {
                    bottom: -10px; }
                  
                  .float.active i {
                    background: transparent; }
                  
                  .float.active i::before {
                    top: 0;
                    -webkit-transform: rotateZ(45deg);
                    -moz-transform: rotateZ(45deg);
                    -ms-transform: rotateZ(45deg);
                    -o-transform: rotateZ(45deg);
                    transform: rotateZ(45deg); }
                  
                  .float.active i::after {
                    bottom: 0;
                    -webkit-transform: rotateZ(-45deg);
                    -moz-transform: rotateZ(-45deg);
                    -ms-transform: rotateZ(-45deg);
                    -o-transform: rotateZ(-45deg);
                    transform: rotateZ(-45deg); }
                  
                  .float {
                    position: fixed;
                    left: -5px;
                    top: 0px;
                    z-index: 9999;
                    cursor: pointer;
                    opacity: 1;
                    visibility: hidden;
                    padding: 20px;
                    -webkit-transition: 0.5s;
                    -o-transition: 0.5s;
                    transition: 0.5s; }
                    @media screen and (max-width: 768px) {
                      .float {
                        opacity: 1;
                        visibility: visible; } }

                        #TheMainPage {
                          width: calc(100% - 300px);
                          float: right;
                          -webkit-transition: 0.5s;
                          -o-transition: 0.5s;
                          transition: 0.5s; }
                          @media screen and (max-width: 768px) {
                            #TheMainPage {
                              width: 100%;
                              padding: 0 1em; } }

                              .colorlib-narrow-content {
                                padding: 0 2.5em; }
                                @media screen and (max-width: 768px) {
                                  .colorlib-narrow-content {
                                    padding: 0; } }

.colorlib-experience,
.colorlib-skills,
.colorlib-education,
.colorlib-blog,
.colorlib-work,
.colorlib-about,
.colorlib-services,
.colorlib-contact {
  padding-top: 4em;
  padding-bottom: 9em;
  clear: both;
  width: 100%;
  display: block; }
  @media screen and (max-width: 768px) {
    .colorlib-experience,
    .colorlib-skills,
    .colorlib-education,
    .colorlib-blog,
    .colorlib-work,
    .colorlib-about,
    .colorlib-services,
    .colorlib-contact {
      padding-top: 5em;
      padding-bottom: 8em; } }

      .colorlib-heading {
        font-size: 18px;
        margin-bottom: 4em;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 5px;
        line-height: 1.8;
        position: relative; }
        .colorlib-heading span {
          display: block; }
        @media screen and (max-width: 768px) {
          .colorlib-heading {
            margin-bottom: 3em; } }

      .heading-meta {
        margin-bottom: 15px;
        display: block;
        font-size: 10px;
        text-transform: uppercase;
        color: #999999;
        font-weight: 500;
        letter-spacing: 5px; }

        #colorlib-aside #colorlib-main-menu ul li.colorlib-active a {
          color: #000000; }
          #colorlib-aside #colorlib-main-menu ul li.colorlib-active a:after {
            visibility: visible;
            -webkit-transform: scaleX(1);
            -moz-transform: scaleX(1);
            -ms-transform: scaleX(1);
            -o-transform: scaleX(1);
            transform: scaleX(1); }