.hero-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0; }
  .hero-wrap .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    opacity: .1;
    background: #fff;
    z-index: 0; }
  .hero-wrap.hero-wrap-2 .overlay {
    opacity: .8; }
  .hero-wrap .text {
    z-index: 1;
    font-size: 18px;
    font-weight: 500; }
    .hero-wrap .text .img {
      width: 200px;
      height: 200px;
      margin: 0 auto;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%; }
    .hero-wrap .text .subheading {
      font-size: 24px;
      color: #212121;
      font-weight: 600; }
    .hero-wrap .text h1 {
      letter-spacing: 1px;
      font-weight: 900;
      font-family: "Poppins", Arial, sans-serif; }
    .hero-wrap .text .btn-custom {
      font-weight: 700;
      color: #212121;
      border-bottom: 2px solid #212121; }

      .hero-wrap {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center; }

        .mb-4 {
          margin-bottom: 1.5rem !important; }