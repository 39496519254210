  .heading {
    font-size: 23px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  
  .accordion {
    list-style: none;
  }
  
  .accordion_item {
    border-top: 1px solid #9f9f9f;
  }
  
  .button {
    /* border: 1px #e6e6e6; */
    font-size: 16px;
    background-color: #f2f3f7;
    color: #000;
    text-transform: uppercase;
    text-align: left;
    font-weight: 700;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 18px 8px;
    cursor: pointer;
    border: none;
    margin-bottom: 10px;
  }
  
  .control {
    font-size: 20px;
    margin-right: 20px;
  }
  
  .answer {
    border: #efefef;
    background-color: #f7f7f7;
    padding: 20px;
  }

  /* activate toggle */
.accordion_item.active .button {
    background-color: #2c98f0;
    color: #fff;
    /* margin-bottom: none; */
  }
  .answer_wrapper {
    /* border: #000 1px solid; */
    height: 0;
    overflow: hidden;
    transition: height ease 0.2s;
  }