#contactrowlist {
    border: 1px solid lightblue;
    display: flex;
    justify-content: space-around;
    height: 150px;
    margin-bottom: 20px;
    width: 100%;
}

#contactitem {
    display: flex;
    flex-direction: column;
    /* flex-basis: 20%; */
}

.contactlabel {
    margin-top: 100000px;
    width: 20%;
    border: 1px solid green;
}

.order {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}