.App {
  text-align: center;
}

.App-logo {
  height: 15vmin; /*10vmin for the loader*/
  pointer-events: none;
}

.App-logo-small {
  height: 10vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #f2f3f7;/*e6ede8*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-family: Georgia, 'Times New Roman', Times, serif;
  color: #333333;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
